<template>
	<div class="resume">
		<div class="resume-title">
			请上传你的简历
		</div>
		<div class="resume-mark">
			简历建议使用PDF文件，也支持DOC、DOCX、JPG格式，大小不超 过20M
		</div>
		<div @click="uploadResume" v-if="!resumeUrl" class="resume-upload">
			<img src="@/assets/images/mobile/common/plus.png" alt="">
		</div>
		<div @click="previewFile" v-else class="resume-file">
			<div class="resume-file-left">
				<img :src="fileIcon" alt="">
				<span>简历.{{resumeType}}</span>
			</div>
			<div class="resume-file-right">
				<span @click.stop="uploadResume" class="">重传</span>
				<span @click.stop="deleteResume" class="">删除</span>
			</div>
		</div>
		<el-upload class="uploadImage" style="display:none;" :action="upLoadUrl" :show-file-list="false"
			:before-upload="beforeUpload" :http-request="handleCustomRequest"></el-upload>
		<tooltip @confirm="confirm" @cancle="cancle" :isShowToolTip="isShowToolTip" :tooltipTitle="'温馨提示'"
			:tooltipContent="'确定要删除该简历吗？'" />
	</div>
</template>

<script>
	import {
		upLoadFileMb
	} from "@/api/mobile/oss"
	// import {
	// 	studentAccountEditMb,
	// 	deleteResumeMb,
	// } from "@/api/mobile/mine"
	import {
		upLoadResumeMb,
		deleteResumeMb
	} from "@/api/mobile/activity"
	import {
		mapActions
	} from 'vuex'
	import storage from 'store'
	export default {
		data() {
			return {
				resumeUrl: "",
				resumeType: "",
				upLoadUrl: "",
				limitSize: 5,
				isShowToolTip: false,
				fileIcon: ""
			}
		},
		mounted() {
			this.postId = this.$route.query.postId
			if(this.$route.query.resume){
				this.resumeUrl = this.$route.query.resume
			}
			// this.resumeUrl = storage.get("accessInfo").resume
			this.getResumeData()
		},
		methods: {
			...mapActions(['mbUserInfo']),
			getResumeData() {
				this.fileIcon = ""
				if(!this.resumeUrl){
					return
				}
				this.resumeType = this.resumeUrl.substring(this.resumeUrl.lastIndexOf(".") + 1);
				if(this.resumeType == 'jpg' || this.resumeType == 'png' || this.resumeType == 'jpeg' || this.resumeType == 'webp') {
					this.fileIcon = require("@/assets/images/mobile/student/jpg.png")
				} else if (this.resumeType == 'doc' || this.resumeType == 'docx') {
					this.fileIcon = require("@/assets/images/mobile/student/doc.png")
				} else {
					this.fileIcon = require("@/assets/images/mobile/student/pdf.png")
				}
				this.$forceUpdate()
			},
			confirm() {
				let params = {
					id:this.postId
				}
				deleteResumeMb(params,this.postId).then(res => {
					if (res.code == 0) {
						this.$toast("简历删除成功")
						this.isShowToolTip = false
						this.resumeUrl = ""
						this.getResumeData()
						// await this.mbUserInfo()
						// this.getResumeData()
					}
				})
			},
			cancle() {
				this.isShowToolTip = false
			},
			deleteResume() {
				this.isShowToolTip = true
			},
			previewFile() {
				if (this.$dd.env.platform !== "notInDingTalk") {
					this.$dd.ready(() => {
						this.$dd.biz.util.openLink({
							url: this.resumeUrl, //要打开链接的地址
							onSuccess: function(result) {
								/**/
							},
							onFail: function(err) {}
						})
					})
				}
			},
			uploadResume() {
				document.querySelector(".uploadImage input").click();
			},
			// 上传按钮   限制图片大小
			beforeUpload(file) {
				console.log("beforeUpload", file);
				const isLt5M = file.size / 1024 / 1024 < this.limitSize;
				if (!isLt5M) {
					this.$toast("上传文件大小不能超过 5MB!")
					return false;
				}
				let fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
				let fileName = file.name;
				// let fileType = file.type;
				if (fileType != "doc" && fileType != "docx" && fileType != "png" && fileType != "jpg" && fileType !=
					"jpeg" && fileType != "webp" && fileType != "pdf") {
					this.$toast("上传文件只能是doc、docx、jpg、pdf格式!")
					return false;
				}
			},
			handleCustomRequest(data) {
				console.log("handleCustomRequest", data)
				this.fileData = []
				const formData = new FormData();
				formData.append('file', data.file);
				upLoadFileMb(formData).then((res) => {
					console.log("上传成功", res)
					// let params = {
					// 	resume: res.data.accessUrl,
					// }
					this.resumeUrl = res.data.accessUrl
					const formDataResume = new FormData();
					formDataResume.append('resume', res.data.accessUrl);
					upLoadResumeMb(formDataResume,this.postId).then( (res) => {
						if (res.code == 0) {
							this.$toast("简历上传成功")
							this.getResumeData()
						}
					})
					// studentAccountEditMb(params).then(async (res) => {
					// 	if (res.code == 0) {
					// 		this.$toast("简历上传成功")
					// 		await this.mbUserInfo()
					// 		this.getResumeData()
					// 	}
					// })
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.resume {
		display: flex;
		flex-direction: column;
		padding: 0 0.28rem;
		box-sizing: border-box;

		.resume-title {
			font-size: 0.32rem;
			font-family: PingFang SC;
			font-weight: bold;
			line-height: 0.44rem;
			color: #303133;
			margin: 0.64rem 0 0.2rem 0;
		}

		.resume-mark {
			font-size: 0.24rem;
			font-family: PingFang SC;
			font-weight: 400;
			line-height: 0.37rem;
			color: #909399;
		}

		.resume-upload {
			width: 6.94rem;
			height: 1.2rem;
			background: #FFFFFF;
			box-shadow: 0rem 0.04rem 0.16rem rgba(220, 223, 230, 0.2000);
			border-radius: 0.12rem;
			margin-top: 0.4rem;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 0.64rem;
				height: 0.64rem;
			}
		}

		.resume-file {
			width: 6.94rem;
			height: 1.2rem;
			background: #FFFFFF;
			box-shadow: 0rem 0.04rem 0.16rem rgba(220, 223, 230, 0.2000);
			border-radius: 0.12rem;
			margin-top: 0.4rem;
			padding: 0 0.32rem;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.resume-file-left {
				display: flex;
				align-items: center;

				img {
					width: 0.7rem;
					height: 0.8rem;
					margin-right: 0.2rem;
				}

				span {
					font-size: 0.28rem;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 0.4rem;
					color: #303133;
				}
			}

			.resume-file-right {
				display: flex;
				align-items: center;

				span {
					font-size: 0.24rem;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 0.34rem;
					color: #4392FA;
					margin-left: 0.16rem;
				}
			}
		}
	}
</style>
